@font-face {
  font-family: "Baloo 2";
  font-weight: 600;
  font-style: normal;
  src: local('Baloo 2'),
       local('Baloo-2'),
       url(../fonts/baloo-2-600-subset.woff2) format("woff2"),
       url(../fonts/baloo-2-600-subset.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  font-weight: 400;
  font-style: normal;
  src: local("Open Sans"),
       local("Open-Sans"),
       url(../fonts/open-sans-400.woff2) format("woff2"),
       url(../fonts/open-sans-400.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  font-style: normal;
  src: local("Open Sans"),
       local("Open-Sans"),
       url(../fonts/open-sans-600.woff2) format("woff2"),
       url(../fonts/open-sans-600.woff) format("woff");
  font-display: swap;
}
