form {
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(255,255,255,0.5);
  padding: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;

  @media (min-width: 800px) {
    padding: 2rem;
  }
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  @include screen_reader_only;
}

fieldset {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & + fieldset {
    margin-top: 2rem;
  }
}

.form_row {
  text-align: left;

  & + .form_row {
    margin-top: 2rem;
  }

  > label {
    font-weight: 600;
  }
}

.button_row {
  margin-top: 2rem;
}
