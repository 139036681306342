h1,
h2,
h3 {
  font-weight: 600;
}

.heading {
  font-family: 'Baloo 2', 'Open Sans', Helvetica, sans-serif;
  line-height: 1;
}

.heading_page {
  font-size: 2rem;
  font-weight: 600;
}

.heading_panel {
  font-size: 2rem;
  margin-top: 0;
}

.heading_section {
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  .heading_page {
    font-size: 3rem;
  }
}
