.terms {
  counter-reset: primary;
  text-align: left;

  section {
    counter-increment: primary;

    + section {
      margin-top: 2rem;
    }

    ol {
      list-style: none;
      padding: 0;
      line-height: 1.5;
    }

    li {
      padding-left: 3rem;
      position: relative;

      &::before {
        position: absolute;
        left: 0;
      }
    }

    > h3 {
      margin-bottom: 0;

      &::before {
        content: counter(primary) ".";
        margin-right: 0.375rem;
      }
    }

    > ol {
      counter-reset: secondary;

      > li {
        counter-increment: secondary;

        > ol {
          counter-reset: tertiary;
          margin-top: 1rem;
          margin-bottom: 1rem;

          > li {
            counter-increment: tertiary;
          }

          > li::before {
            content: counter(primary) "." counter(secondary) "." counter(tertiary) ".";
          }
        }
      }

      > li::before {
        content: counter(primary) "." counter(secondary) ".";
      }

    }
  }
}
