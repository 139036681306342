main {
  display: flex;
  flex-direction: column;
  max-width: 40rem;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  align-items: flex-start;

  > * {
    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }
  }
}

article {
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 2rem;
}

body > article {
  margin-top: 2rem;
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}

main,
article {
  :first-child {
    margin-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 800px) {
  main {
    max-width: 60rem;
    flex-direction: row;
    text-align: left;
    margin-top: 4rem;
  }

  main > * {
    flex-grow: 1;
    flex-basis: 50%;
  }

  article {
    padding: 2rem;

    + form {
      margin-left: 4rem;
    }
  }
}
