button {
  font-weight: 600;
  font-family: inherit;
  font-size: 1.125rem;
  border-radius: 5px;
  padding: 0.75rem 1.25rem;
  border-width: 1px;
  border-color: currentColor;
  border-style: solid;
  background-color: rgba(0,0,0,.8);
  transition: opacity 0.3s ease-in;
  color: #fff;

  &[disabled] {
    opacity: 0.75;
  }
}
