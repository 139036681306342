html {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  background-color:#89bce6;
  color:#000;
  background-image: url(../img/super-saturday-hero-bg.svg);
  font-family: 'Open Sans', Helvetica, sans-serif;
  line-height: 1.25;
  -webkit-font-feature-settings: 'kern';
  font-feature-settings: 'kern';
  padding: 0.5rem;
  text-align: center;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: baseline;
}

abbr {
  text-decoration: none;
}

b,
strong {
  font-weight: 600;
}
