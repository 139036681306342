input, textarea {
  font-family: inherit;
  font-size: inherit;
}

input:focus,
button:focus {
  outline: none;
  box-shadow: 0 0 0 3px #007bff;
}

[type="text"],
[type="email"],
[type="tel"],
[type="date"],
textarea {
  display: block;
  border-radius: 5px;
  padding: 0.5rem;
  background-color: rgba(255,255,255,0.8);
  transition-property: background-color;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  border-width: 1px;
  border-color: rgba(0,0,0,.25);
  border-style: solid;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    background-color: rgba(255,255,255,1);
  }
}

[type="text"],
[type="email"],
[type="tel"],
textarea {
  -webkit-appearance: none;
}

[type="date"] {
  // IE 11 fix
  min-height: 1em;
  // iOS Safari width setting
  -webkit-min-logical-width: calc(100% - 16px);
}

.form_field {
  margin-top: 0.25rem;

  > label {
    display: inline-block;
    padding: 0.5rem;
    background-color: rgba(255,255,255,0.8);
    border-radius: 0.25rem;
  }
}

.form_field ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  display: flex;
  flex-direction: row;

  li + li {
    margin-left: 0.5rem;
  }

  label {
    display: inline-block;
    padding: 0.5rem;
    background-color: rgba(255,255,255,0.8);
    border-radius: 0.25rem;
  }
}
