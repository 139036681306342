// Just mirroring bootstrap here
.alert.alert-danger {
  color: black;
  background-color: #f2977b;
  border-color: #f2977b;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}
